"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getUnitAttributes = attributeCategories => attributeCategories.map(attributeCategory => _objectSpread(_objectSpread({}, attributeCategory), {}, {
  attributes: attributeCategory.attributes
})).filter(category => category.attributes.length > 0);
const UnitAttributes = ({
  data,
  name,
  registrationNumber
}) => {
  const {
    open,
    onToggle
  } = (0, _useSesame.default)(false);
  const ref = (0, _react.useRef)(null);
  return __jsx(StyledWrapper, null, __jsx(_Heading.default, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "DdzRg2",
    defaultMessage: [{
      "type": 0,
      "value": "Kenmerken "
    }, {
      "type": 1,
      "value": "name"
    }],
    values: {
      name
    }
  }))), __jsx(Container, {
    open: open
  }, __jsx(StyledGrid, null, getUnitAttributes(data).map(category => __jsx(CategoryAttributes, {
    category: category,
    key: category.name
  }))), registrationNumber && __jsx(StyledFlex, {
    alignItems: "center"
  }, __jsx(StyledBody, {
    variant: "small",
    elementType: "h3"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "xDYlP7",
    defaultMessage: [{
      "type": 0,
      "value": "Gemeentelijk registratienummer:"
    }]
  }), " \xA0"), registrationNumber)), __jsx(StyledButton, {
    ref: ref,
    variant: "outline",
    onClick: () => {
      if (open) {
        onToggle();
        setTimeout(() => ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        }), 150);
      } else {
        onToggle();
      }
    }
  }, open ? __jsx(_reactIntl.FormattedMessage, {
    id: "oBgVzm",
    defaultMessage: [{
      "type": 0,
      "value": "Lees minder"
    }]
  }) : __jsx(_reactIntl.FormattedMessage, {
    id: "EbRgER",
    defaultMessage: [{
      "type": 0,
      "value": "Lees meer"
    }]
  })));
};
const CategoryAttributes = ({
  category
}) => {
  const {
    onOpen,
    open,
    onClose
  } = (0, _useSesame.default)();
  const hasMore = category.attributes.length > 5;
  return __jsx(Category, {
    key: category.name,
    break: category.attributes.length > 6
  }, __jsx(ColumnTitle, {
    variant: "small",
    elementType: "h3"
  }, category.name), __jsx(List, null, category.attributes.slice(0, 5).map(attribute => __jsx(Attribute, {
    key: attribute.name,
    variant: "regular",
    elementType: "li"
  }, attribute.name)), hasMore && __jsx(More, null, __jsx(MoreTextButton, {
    onClick: onOpen
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "EbRgER",
    defaultMessage: [{
      "type": 0,
      "value": "Lees meer"
    }]
  })), __jsx(_Modal.default, {
    variant: "small",
    open: open,
    onClose: onClose
  }, __jsx(_ModalHeader.default, {
    style: {
      marginBottom: '1.6rem'
    }
  }, category.name), category.attributes.map(attribute => __jsx(Attribute, {
    key: attribute.name,
    variant: "regular",
    elementType: "li"
  }, attribute.name))))));
};
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitAttributes__StyledBody",
  componentId: "sc-34e1io-0"
})(["font-weight:800;display:inline-block;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "UnitAttributes__Container",
  componentId: "sc-34e1io-1"
})(["display:flex;flex-direction:column;flex-wrap:wrap;max-height:", ";overflow:", ";position:relative;", " @media (min-width:", "){max-height:none;::after{content:none;}}"], ({
  open
}) => open ? 'none' : '16rem', ({
  open
}) => open ? 'initial' : 'hidden', ({
  open
}) => !open && `
        ::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5.6rem;
            width: 100%;
            background: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }`, ({
  theme
}) => theme.mediaQueries.s);
const StyledGrid = _styledComponents.default.div.withConfig({
  displayName: "UnitAttributes__StyledGrid",
  componentId: "sc-34e1io-2"
})(["columns:2;column-gap:3.2rem;position:relative;@media (min-width:", "){columns:3;}@media (min-width:", "){columns:6;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.l);
const Category = _styledComponents.default.div.withConfig({
  displayName: "UnitAttributes__Category",
  componentId: "sc-34e1io-3"
})(["position:relative;padding-bottom:", ";break-inside:avoid;page-break-inside:avoid;"], ({
  theme
}) => theme.spacing['50_Semi']);
const List = _styledComponents.default.ul.withConfig({
  displayName: "UnitAttributes__List",
  componentId: "sc-34e1io-4"
})(["padding:0;margin:0;display:inline;"]);
const StyledButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "UnitAttributes__StyledButton",
  componentId: "sc-34e1io-5"
})(["margin-top:2.2rem;@media (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.s);
const StyledWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "UnitAttributes__StyledWrapper",
  componentId: "sc-34e1io-6"
})(["padding-top:0;"]);
const ColumnTitle = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitAttributes__ColumnTitle",
  componentId: "sc-34e1io-7"
})(["margin-bottom:", ";font-weight:800;display:inline-block;"], ({
  theme
}) => theme.spacing['20_Tiny']);
const StyledFlex = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "UnitAttributes__StyledFlex",
  componentId: "sc-34e1io-8"
})(["width:auto !important;"]);
const Attribute = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitAttributes__Attribute",
  componentId: "sc-34e1io-9"
})(["margin-bottom:", ";display:inline-block;width:100%;&:first-child{break-before:avoid-column;}"], ({
  theme
}) => theme.spacing['20_Tiny']);
const More = _styledComponents.default.li.withConfig({
  displayName: "UnitAttributes__More",
  componentId: "sc-34e1io-10"
})(["break-before:avoid-column;display:inline-block;width:100%;"]);
const MoreTextButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "UnitAttributes__MoreTextButton",
  componentId: "sc-34e1io-11"
})(["color:", ";"], ({
  theme
}) => theme.colorAssignments.textAction);
var _default = exports.default = UnitAttributes;