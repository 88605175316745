"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _groupImagesByCategory = require("../utils/groupImagesByCategory");
var _Carousel = _interopRequireDefault(require("./designsystem/Carousel"));
var _PhotoCategory = _interopRequireDefault(require("./PhotoCategory"));
var __jsx = _react.default.createElement;
const PhotoCategoryGallery = ({
  data,
  onCategoryClick,
  title
}) => {
  const groupedImages = (0, _groupImagesByCategory.groupImagesByCategory)(data);
  const rooms = groupedImages.map(({
    category,
    images
  }, index) => {
    const image = images[0];
    if (!image?.jpegTile || !image?.webpTile) {
      return null;
    }
    return __jsx(_PhotoCategory.default, {
      key: category,
      category: category,
      jpeg: image.jpegTile,
      webp: image.webpTile,
      count: images?.length,
      onClick: () => {
        onCategoryClick(index);
      }
    });
  });
  return __jsx(_Carousel.default, {
    title: title,
    continuous: true
  }, rooms);
};
var _default = exports.default = PhotoCategoryGallery;