"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _accommodationImageCategoryTexts = _interopRequireDefault(require("../constants/accommodationImageCategoryTexts"));
var _Tile = _interopRequireDefault(require("./designsystem/Tile"));
const _excluded = ["count", "category", "onClick"];
var __jsx = _react.default.createElement;
const PhotoCategory = _ref => {
  let {
      count,
      category,
      onClick
    } = _ref,
    photoProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(Wrapper, null, __jsx(Container, {
    onClick: onClick
  }, __jsx(_Tile.default, (0, _extends2.default)({
    loading: "lazy"
  }, photoProps)), __jsx(Text, null, __jsx(_Title.default, {
    variant: "tiny"
  }, _accommodationImageCategoryTexts.default[category]), __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "myJ8mF",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "count"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "count",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "foto"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "foto's"
          }]
        }
      }
    }],
    values: {
      count
    }
  })))));
};
const Wrapper = _styledComponents.default.div.withConfig({
  displayName: "PhotoCategory__Wrapper",
  componentId: "sc-1mppq8s-0"
})(["scroll-snap-align:start;& + &{margin-left:3.2rem;}&:last-child{margin-right:3.2rem;}"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "PhotoCategory__Container",
  componentId: "sc-1mppq8s-1"
})(["display:flex;flex-direction:column;width:32rem;cursor:pointer;@media (min-width:", "){width:44.8rem;height:100%;}"], ({
  theme
}) => theme.mediaQueries.m);
const Text = _styledComponents.default.div.withConfig({
  displayName: "PhotoCategory__Text",
  componentId: "sc-1mppq8s-2"
})(["display:flex;flex-direction:column;margin-top:", ";"], ({
  theme
}) => theme.spacing['40_Standard']);
var _default = exports.default = PhotoCategory;