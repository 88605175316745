"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StyledContentWrapper = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _grid = require("@rebass/grid");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var __jsx = _react.default.createElement;
const defaultPassportTitle = 'Frysk Paspoart';
const defaultPassportMessage = 'Een vakantie in Friesland laat je hart sneller kloppen. Na een bezoek aan Friesland krijgt onze provincie voor altijd een bijzonder plekje in je hart. Om jezelf ook een heel klein beetje Fries te voelen, krijg je bij je boeking een écht Frysk Paspoart (Fries Paspoort). Dit paspoort staat vol met weetjes en informatie over onze cultuur en taal. {br}{br} Het Frysk Paspoart haal je tijdens je verblijf in Friesland op in één van onze geselecteerde VVV winkels (zie boekingsbevestiging). Met dit paspoort heb je recht op 10% korting op onze VVV informatie- en inspiratie producten.';
const deTochtTitle = 'De Tocht overnachtingsarrangement';
const deTochtMessage = `Onze accommodaties doen voor jou graag extra hun best om je gastvrij in Friesland te ontvangen. Daarom hebben zij speciaal voor De Tocht bijzondere arrangementen samengesteld. De inhoud van dit arrangement vind je op deze pagina onder het kopje ‘bijzonder aan deze accommodatie’.{br}{br}{bewareText}`;
const showCustomPassportContent = process.env.NEXT_PUBLIC_BRAND === 'detocht';
const Passport = () => {
  const {
    brandConfig: {
      passport
    }
  } = (0, _TenantContext.useTenantContext)();
  const intl = (0, _reactIntl.useIntl)();
  const {
    mediaQueries
  } = (0, _useThemeContext.useThemeContext)();
  const formattedMessage = {
    title: showCustomPassportContent ? __jsx(_reactIntl.FormattedMessage, {
      id: "h1Ff0g",
      defaultMessage: [{
        "type": 0,
        "value": "De Tocht overnachtingsarrangement"
      }]
    }) : __jsx(_reactIntl.FormattedMessage, {
      id: "HLCP1f",
      defaultMessage: [{
        "type": 0,
        "value": "Frysk Paspoart"
      }]
    }),
    message: showCustomPassportContent ? __jsx(_reactIntl.FormattedMessage, {
      values: {
        br: __jsx("br", null),
        bewareText: __jsx("b", null, "LET OP! Iedereen die boekt via onze site en de VVV krijgt gratis een unieke backstage rondleiding aangeboden t.w.v. \u20AC 12,50 p.p. (de rondleiding is voor alle gasten die onder de boeking vallen). Na je reservering ontvang je van VVV Waterland van Friesland een email waarmee je de backstage rondleiding kan reserveren.")
      },
      id: "b1st2Z",
      defaultMessage: [{
        "type": 0,
        "value": "Onze accommodaties doen voor jou graag extra hun best om je gastvrij in Friesland te ontvangen. Daarom hebben zij speciaal voor De Tocht bijzondere arrangementen samengesteld. De inhoud van dit arrangement vind je op deze pagina onder het kopje ‘bijzonder aan deze accommodatie’."
      }, {
        "type": 1,
        "value": "br"
      }, {
        "type": 1,
        "value": "br"
      }, {
        "type": 1,
        "value": "bewareText"
      }]
    }) : __jsx(_reactIntl.FormattedMessage, {
      values: {
        br: __jsx("br", null)
      },
      id: "Fnm3eb",
      defaultMessage: [{
        "type": 0,
        "value": "Een vakantie in Friesland laat je hart sneller kloppen. Na een bezoek aan Friesland krijgt onze provincie voor altijd een bijzonder plekje in je hart. Om jezelf ook een heel klein beetje Fries te voelen, krijg je bij je boeking een écht Frysk Paspoart (Fries Paspoort). Dit paspoort staat vol met weetjes en informatie over onze cultuur en taal. "
      }, {
        "type": 1,
        "value": "br"
      }, {
        "type": 1,
        "value": "br"
      }, {
        "type": 0,
        "value": " Het Frysk Paspoart haal je tijdens je verblijf in Friesland op in één van onze geselecteerde VVV winkels (zie boekingsbevestiging). Met dit paspoort heb je recht op 10% korting op onze VVV informatie- en inspiratie producten."
      }]
    })
  };
  if (!passport) {
    return null;
  }
  const {
    imgSrc
  } = passport;
  return __jsx(Container, {
    my: [6, 8],
    pt: [6, 8],
    flexDirection: ['column', null, 'row'],
    justifyContent: "space-around",
    alignItems: "center",
    className: "passport-container"
  }, __jsx(StyledBoxImage, {
    width: [1, 1, 1, 1 / 2],
    mb: [0, 0, 6, 0],
    className: "passport-image-container"
  }, showCustomPassportContent ? __jsx(CustomImage, {
    src: imgSrc,
    alt: deTochtTitle,
    layout: "fill",
    sizes: `(min-width: ${mediaQueries.m}) 50vw, 100vw`,
    style: {
      objectFit: 'contain'
    }
  }) : __jsx(_image.default, {
    src: imgSrc,
    alt: intl.formatMessage({
      "id": "HLCP1f",
      "defaultMessage": [{
        "type": 0,
        "value": "Frysk Paspoart"
      }]
    }),
    layout: "fill",
    sizes: `(min-width: ${mediaQueries.m}) 50vw, 100vw`
  })), __jsx(_grid.Box, {
    mt: [5, null, 0],
    width: [1 / 1, 1 / 1, 1 / 1, 1 / 2],
    ml: [0, 0, 0, 7]
  }, __jsx(_Title.default, {
    variant: "large"
  }, formattedMessage.title), __jsx(StyledBox, {
    mt: [2, null, 5]
  }, __jsx(_Body.default, null, formattedMessage.message))));
};
var _default = exports.default = Passport;
const Container = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "Passport__Container",
  componentId: "sc-1rip0gb-0"
})(["justify-content:space-between;align-items:flex-start;flex-direction:column;@media screen and (min-width:", "){flex-direction:row;}@media screen and (min-width:", "){align-items:center;}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.xl);
const StyledBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Passport__StyledBox",
  componentId: "sc-1rip0gb-1"
})(["p{margin-bottom:0.8rem;@media screen and (min-width:", "){margin-bottom:1.6rem;}}"], ({
  theme
}) => theme.mediaQueries.xl);
const CustomImage = (0, _styledComponents.default)(_image.default).withConfig({
  displayName: "Passport__CustomImage",
  componentId: "sc-1rip0gb-2"
})(["object-fit:contain;background:", ";"], ({
  theme
}) => theme.colors.neutral['0']);
const StyledBoxImage = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Passport__StyledBoxImage",
  componentId: "sc-1rip0gb-3"
})(["position:relative;overflow:hidden;aspect-ratio:4 / 3;background-color:", ";"], ({
  theme
}) => theme.colors.primary['20']);
const StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "Passport__StyledContentWrapper",
  componentId: "sc-1rip0gb-4"
})(["padding:0 var(--wrapperPadding);max-width:calc(140.8rem + var(--wrapperPadding) * 2);margin:0 auto ", ";border-top:1px solid ", ";border-bottom:1px solid ", ";padding-top:", ";padding-bottom:", ";"], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.spacing['60_Large']);