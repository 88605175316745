"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _graphql = require("../generated/graphql");
const getSqzlyCategoryNumber = type => {
  switch (type) {
    case _graphql.RentalUnitTypeEnum.Home:
      return 1;
    case _graphql.RentalUnitTypeEnum.Tent:
      return 2;
    case _graphql.RentalUnitTypeEnum.Room:
      return 3;
    case _graphql.RentalUnitTypeEnum.CamperSpot:
      return 4;
    case _graphql.RentalUnitTypeEnum.CampingPitch:
      return 5;
    case _graphql.RentalUnitTypeEnum.Bed:
      return 6;
    default:
      return 8;
  }
};
const getSqzlyCategoryName = (prefix, locale, type) => [prefix, getSqzlyCategoryNumber(type), locale.toUpperCase()].join('');
var _default = exports.default = getSqzlyCategoryName;