"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitPhotosFragment = exports.fragment = exports.default = exports.accommodationPhotos = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _reactIntl = require("react-intl");
var _PhotoCategoryGallery = _interopRequireDefault(require("./PhotoCategoryGallery"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _client = require("@apollo/client");
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
const _excluded = ["onOpenPhotos"];
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment Images on AccommodationImage {
        category
        jpegTile: transform(config: TILE, format: JPEG) {
            ...Transform
        }
        webpTile: transform(config: TILE, format: WEBP) {
            ...Transform
        }
        jpegGallery: transform(config: GALLERY, format: JPEG) {
            ...Transform
        }
        webpGallery: transform(config: GALLERY, format: WEBP) {
            ...Transform
        }
    }

    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;
const unitPhotosFragment = exports.unitPhotosFragment = (0, _client.gql)`
    fragment UnitPhotos on RentalUnit {
        images {
            ...Images
        }
    }
`;
const accommodationPhotos = exports.accommodationPhotos = (0, _client.gql)`
    fragment AccommodationPhotos on Accommodation {
        images {
            ...Images
        }
    }
`;
const UnitPhotos = _ref => {
  let {
      onOpenPhotos
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(Container, null, __jsx(_PhotoCategoryGallery.default, props), __jsx(StyledWrapper, null, __jsx(_Button.default, {
    variant: "outline",
    onClick: onOpenPhotos
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "ainnTy",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk alle ruimtes"
    }]
  }))));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "UnitPhotos__Container",
  componentId: "sc-1xagr8w-0"
})(["display:none;@media (min-width:", "){display:block;}"], ({
  theme
}) => theme.mediaQueries.xs);
const StyledWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "UnitPhotos__StyledWrapper",
  componentId: "sc-1xagr8w-1"
})(["padding-top:0;--wrapperPadding:0;"]);
var _default = exports.default = UnitPhotos;