"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupImagesByCategory = exports.groupImageByCategoryClean = exports.accomodationImagesOrder = void 0;
var _accommodationImageCategoryTexts = _interopRequireDefault(require("../constants/accommodationImageCategoryTexts"));
var _graphql = require("../generated/graphql");
const accomodationImagesOrder = exports.accomodationImagesOrder = [_graphql.AccommodationImageCategoryEnum.Front, _graphql.AccommodationImageCategoryEnum.LivingRoom, _graphql.AccommodationImageCategoryEnum.Kitchen, _graphql.AccommodationImageCategoryEnum.Bedroom, _graphql.AccommodationImageCategoryEnum.Entrance, _graphql.AccommodationImageCategoryEnum.Bathroom, _graphql.AccommodationImageCategoryEnum.Outdoor, _graphql.AccommodationImageCategoryEnum.View, _graphql.AccommodationImageCategoryEnum.Facilities, _graphql.AccommodationImageCategoryEnum.Surroundings, _graphql.AccommodationImageCategoryEnum.FloorPlan, _graphql.AccommodationImageCategoryEnum.Other];
const groupImagesByCategory = images => {
  const groupedImages = [];
  accomodationImagesOrder.forEach(category => {
    const filteredImages = images.filter(image => image.category === category);
    if (filteredImages.length > 0) {
      groupedImages.push({
        category,
        images: filteredImages
      });
    }
  });
  return groupedImages;
};
exports.groupImagesByCategory = groupImagesByCategory;
const groupImageByCategoryClean = (images, full = false) => {
  const groupedImages = [];
  accomodationImagesOrder.forEach(category => {
    const filteredImages = images.filter(image => image.category === category).map(image => ({
      jpeg: {
        ratio: image[full ? 'jpegGallery' : 'jpegTile']?.ratio,
        src: image[full ? 'jpegGallery' : 'jpegTile']?.src,
        srcSet: image[full ? 'jpegGallery' : 'jpegTile']?.srcSet,
        placeholder: image[full ? 'webpGallery' : 'webpTile']?.placeholder
      },
      webp: {
        ratio: image[full ? 'webpGallery' : 'webpTile']?.ratio,
        src: image[full ? 'webpGallery' : 'webpTile']?.src,
        srcSet: image[full ? 'webpGallery' : 'webpTile']?.srcSet,
        placeholder: image[full ? 'webpGallery' : 'webpTile']?.placeholder
      }
    }));
    if (filteredImages.length > 0) {
      groupedImages.push({
        translatedCategoryName: _accommodationImageCategoryTexts.default[category],
        images: filteredImages
      });
    }
  });
  return groupedImages;
};
exports.groupImageByCategoryClean = groupImageByCategoryClean;