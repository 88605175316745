"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getLanguage = locale => {
  switch (locale) {
    case 'en':
    default:
      return 'en-US';
    case 'nl':
      return 'nl-NL';
    case 'de':
      return 'de-DE';
  }
};
var _default = exports.default = getLanguage;