"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
const accommodationImageCategoryTexts = {
  FRONT: __jsx(_reactIntl.FormattedMessage, {
    id: "HKCV6x",
    defaultMessage: [{
      "type": 0,
      "value": "Vooraanzicht"
    }]
  }),
  LIVING_ROOM: __jsx(_reactIntl.FormattedMessage, {
    id: "6R2dzi",
    defaultMessage: [{
      "type": 0,
      "value": "Woonkamer"
    }]
  }),
  KITCHEN: __jsx(_reactIntl.FormattedMessage, {
    id: "IBbIl1",
    defaultMessage: [{
      "type": 0,
      "value": "Keuken"
    }]
  }),
  BEDROOM: __jsx(_reactIntl.FormattedMessage, {
    id: "RZ8cui",
    defaultMessage: [{
      "type": 0,
      "value": "Slapen"
    }]
  }),
  ENTRANCE: __jsx(_reactIntl.FormattedMessage, {
    id: "cDHPdY",
    defaultMessage: [{
      "type": 0,
      "value": "Entree/receptie"
    }]
  }),
  BATHROOM: __jsx(_reactIntl.FormattedMessage, {
    id: "7E44GX",
    defaultMessage: [{
      "type": 0,
      "value": "Sanitair"
    }]
  }),
  OUTDOOR: __jsx(_reactIntl.FormattedMessage, {
    id: "20stAf",
    defaultMessage: [{
      "type": 0,
      "value": "Buitenruimte"
    }]
  }),
  VIEW: __jsx(_reactIntl.FormattedMessage, {
    id: "hEBw24",
    defaultMessage: [{
      "type": 0,
      "value": "Uitzicht"
    }]
  }),
  FACILITIES: __jsx(_reactIntl.FormattedMessage, {
    id: "wsWel/",
    defaultMessage: [{
      "type": 0,
      "value": "Faciliteiten"
    }]
  }),
  SURROUNDINGS: __jsx(_reactIntl.FormattedMessage, {
    id: "NkvXOz",
    defaultMessage: [{
      "type": 0,
      "value": "Omgeving"
    }]
  }),
  FLOOR_PLAN: __jsx(_reactIntl.FormattedMessage, {
    id: "ZHF3uJ",
    defaultMessage: [{
      "type": 0,
      "value": "Plattegrond"
    }]
  }),
  OTHER: __jsx(_reactIntl.FormattedMessage, {
    id: "gN+HQX",
    defaultMessage: [{
      "type": 0,
      "value": "Overig"
    }]
  })
};
var _default = exports.default = accommodationImageCategoryTexts;