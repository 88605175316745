"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _getSqzlyCategoryName = _interopRequireDefault(require("../../utils/getSqzlyCategoryName"));
var _useQueryParams = _interopRequireDefault(require("../useQueryParams"));
var _bookingParams = _interopRequireDefault(require("../../constants/bookingParams"));
var _reactIntl = require("react-intl");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _usePrevious = _interopRequireDefault(require("../usePrevious"));
var _getUnitTypeConsts = require("../../constants/getUnitTypeConsts");
var _getLanguage = _interopRequireDefault(require("../../utils/getLanguage"));
const useAnalyticsUnitViewList = hits => {
  const [booking] = (0, _useQueryParams.default)(_bookingParams.default);
  const intl = (0, _reactIntl.useIntl)();
  const ids = hits?.map(hit => hit.rentalUnit.id);
  const prevIds = (0, _usePrevious.default)(ids ?? []);
  (0, _react.useEffect)(() => {
    if (!hits || (0, _lodash.default)(prevIds, ids) || hits.length === 0) {
      return;
    }
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({
      ecommerce: null
    });
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        items: hits.map(hit => ({
          item_category: _getUnitTypeConsts.unitTypeMessages[hit.rentalUnit.type].defaultMessage[0].value,
          item_id: hit.rentalUnit.code,
          item_name: hit.rentalUnit.name,
          item_variant: booking.arrivalDate && booking.departureDate ? `${booking.departureDate} - ${booking.arrivalDate}` : null,
          price: hit.trips?.[0]?.price,
          currency: 'EUR',
          quantity: 1
        }))
      },
      squeezelyProducts: hits.map(hit => ({
        id: hit.rentalUnit.code,
        name: hit.rentalUnit.name,
        category_id: hit.rentalUnit ? (0, _getSqzlyCategoryName.default)('T', intl.locale, hit.rentalUnit.type) : '',
        checkin_date: booking?.arrivalDate,
        checkout_date: booking?.departureDate,
        language: (0, _getLanguage.default)(intl.locale),
        price: hit.trips?.[0]?.price,
        quantity: 1
      }))
    });
  }, [booking, hits, ids, intl.locale, prevIds]);
};
var _default = exports.default = useAnalyticsUnitViewList;