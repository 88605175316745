"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _grid = require("@rebass/grid");
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Page = _interopRequireDefault(require("./Page"));
var _Passport = _interopRequireDefault(require("./Passport"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _UspsModal = _interopRequireDefault(require("./UspsModal"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const UspsContent = () => {
  const {
    brandConfig: {
      showUsps,
      passport,
      usps
    }
  } = (0, _TenantContext.useTenantContext)();
  const {
    0: visibleIndex,
    1: setVisibleIndex
  } = (0, _react.useState)(0);
  const modal = (0, _useSesame.default)();
  if (!showUsps && !passport) {
    return null;
  }
  return __jsx(_Page.default, null, __jsx(_UspsModal.default, {
    visibleIndex: visibleIndex,
    onClose: modal.onClose,
    open: modal.open
  }), __jsx(StyledContentWrapper, {
    className: "ups-content-container"
  }, passport && __jsx(_Passport.default, null), __jsx(Grid, {
    length: usps?.length,
    my: [4, 8],
    py: [4, 8],
    px: [7, 0]
  }, usps?.map(({
    title
  }, i) => __jsx(USP, {
    onClick: () => {
      setVisibleIndex(i);
      modal.onOpen();
    },
    key: i
  }, title)))));
};
var _default = exports.default = UspsContent;
const Grid = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "UspsContent__Grid",
  componentId: "cukrg7-0"
})(["border-top:1px solid ", ";@media screen and (min-width:", "){display:grid;grid-template-columns:repeat(2,1fr);grid-gap:3.6rem;}@media screen and (min-width:", "){grid-template-columns:", ";}"], ({
  theme
}) => theme.colors.neutral[20], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.m, ({
  length
}) => `repeat(${length}, 1fr)`);
const StyledContentWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "UspsContent__StyledContentWrapper",
  componentId: "cukrg7-1"
})(["> div{border-top:1px solid ", ";margin-top:", ";padding-top:", ";@media screen and (min-width:", "){margin-top:", ";padding-top:", ";}}"], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['80_XXLarge'], ({
  theme
}) => theme.spacing['80_XXLarge']);
const USP = _styledComponents.default.button.withConfig({
  displayName: "UspsContent__USP",
  componentId: "cukrg7-2"
})(["flex-direction:row;align-items:center;color:", ";justify-content:flex-start;display:flex;margin-top:", ";", ";text-align:left;@media screen and (min-width:", "){flex-direction:column;text-align:center;}p{font-weight:500;@media screen and (min-width:", "){min-height:4.8rem;}}&:last-child{grid-column:span 2;@media screen and (min-width:", "){grid-column:auto;}}svg{transition:transform 0.2s ease-in-out;max-width:2rem;margin-right:2.1rem;@media screen and (min-width:", "){margin-right:0;}path,circle{stroke-width:3;}@media screen and (min-width:", "){margin-bottom:", ";max-width:unset;path,circle{stroke-width:2;}}}:hover{svg{transform:scale(1.1);}}@media screen and (min-width:", "){grid-column:auto;}"], ({
  theme
}) => theme.colors.secondary['50'], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.regular), ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueries.m);